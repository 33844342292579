<template>
  <div class="jobs">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: #2cc990"
      :title="title.titlePage"
      :subtitle="title.subtitlePage"
      :img="title.img"
    />
    <InformationsTemplate :card="caritas" />
    <InformationsTemplate :card="escola" />
    <InformationsTemplate :card="jovem" />
    <InformationsTemplate :card="manpowergroup" />
    <InformationsTemplate :card="ministerio" />
    <InformationsTemplate :card="sine" />
    <InformationsTemplate :card="somamos" />
    <InformationsTemplate :card="toti" />
    <InformationsTemplate :card="trabalhabrasil" />
    <p>
      <router-link :to="{ name: 'refugees-menu-PT' }">{{
        $t("jobs.linkPage")
      }}</router-link>
    </p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
  data() {
    return {
      title: {
        titlePage: this.$t("jobs.titlePage"),
        subtitlePage: this.$t("jobs.subtitlePage"),
        img: "jobs.png",
      },
      ministerio: {
        title: this.$t("jobs.ministerioTitle"),
        link: "https://www.gov.br/trabalho/pt-br",
        linkName: this.$t("jobs.linkText"),
      },
      escola: {
        title: this.$t("jobs.escolaTitle"),
        link: "https://www.pronatec.pro.br/escola-do-trabalhador/",
        linkName: this.$t("jobs.linkText"),
      },
      caritas: {
        title: this.$t("legal.caritasTitle"),
        text: this.$t("legal.caritasText"),
        link: "http://www.caritas-rj.org.br/",
        linkName: this.$t("legal.caritasLinkName"),
      },
      trabalhabrasil: {
        title: this.$t("jobs.trabalhaBrasilTitle"),
        link: "http://www.trabalhabrasil.com.br",
        linkName: this.$t("jobs.linkText"),
      },
      manpowergroup: {
        title: this.$t("jobs.manPowerGroupTitle"),
        link: "http://www.manpowergroup.com.br",
        linkName: this.$t("jobs.linkText"),
      },
      toti: {
        title: this.$t("jobs.totiTitle"),
        link: "http://www.totidiversidade.com.br",
        linkName: this.$t("jobs.linkText"),
      },
      somamos: {
        title: this.$t("jobs.somamosTitle"),
        link: "http://www.somamos.net.br",
        linkName: this.$t("jobs.linkText"),
      },
      sine: {
        title: this.$t("jobs.sineTitle"),
        link: "https://www.gov.br/pt-br/servicos/acessar-a-plataforma-sine-aberto",
        linkName: this.$t("jobs.linkText"),
      },
      jovem: {
        title: this.$t("jobs.jovemTitle"),
        link: "https://programajovemaprendiz.org/vaga-de-emprego/rio-de-janeiro-rj/page/6/",
        linkName: this.$t("jobs.linkText"),
      },
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>